.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: no-repeat url('/public/images/register-bg.webp');
  background-size: 100%;
  background-color: #d3d7d8;
  text-align: center;
  gap: 16px;
}

.side {
  flex-shrink: 0;
  width: 36%;
  padding: 20px var(--container-padding);
  background: var(--grey-5);
  background: url('/public/images/account-bg.webp');
  background-size: cover;
}

.logoText path {
  fill: var(--color-black-primary)
}

.lang :global(.forma-ui__lang-switch-dropdown__dropdown) {
  font-size: 12px;
  gap: 5px;
}

.lang :global(.forma-ui__lang-switch-dropdown__dropdownIcon) {
  width: 16px;
  height: 16px;
}

.lang :global(.forma-ui__lang-switch-dropdown__dropdownArrow) {
  width: 8px;
}

.content {
  width: 100%;
  max-width: 430px;
  padding: 32px 24px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-l);
  box-shadow: 0px 1px 3px 0px #0000004D, 0px 4px 8px 3px #00000026;

  --input-height: 40px;
  --input-radius: var(--border-radius-s);
}

.contentHead {
  margin-bottom: 16px;
}

.title {
  margin: 0;
  font-family: var(--main-font);
  font-size: 1.25rem;
  line-height: 1.2;
}

.subtitle {
  margin-top: 16px;
  color: var(--grey-2);
}

.question {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.content :global(.forma-ui__button__primary),
.content :global(.forma-ui__button__secondary) {
  min-height: 40px;
  padding: 8px 16px;
  font-size: 15px;
  font-weight: 500;
  border-radius: var(--border-radius-s);
}

.content :global(.forma-ui__field-container__errorText) {
  margin: 8px 0 0;
}

@media (max-width: 575px) {
  .root {
    align-items: stretch;
    justify-content: flex-start;
    padding: 10px var(--container-padding) 20px;
    background-image: none;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .content {
    max-width: 100%;
    padding: 24px 16px;
  }

  .langPhone :global(.forma-ui__lang-switch-dropdown__dropdownIcon) {
    width: 26px;
    height: 26px;
  }
}
