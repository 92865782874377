.inputs {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-3);
  gap: 20px;

  --input-height: 40px;
  --input-radius: var(--border-radius-s);
  --input-padding-y: 6px;
  --input-font-size: 15px;
}

.dates {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.dates :global(.forma-ui__field-container__label) {
  margin-right: -50px;
}

.datesDivider {
  display: flex;
  align-items: center;
  height: var(--input-height);
}

.datesCheckbox>label {
  padding-top: 11px;
  padding-bottom: 11px;
}

.headButtons {
  margin-top: 0;
}

.headButtons+div {
  margin-top: 20px;
}

.buttonAddCategory,
.button {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
  gap: 8px;
}

.category {
  display: flex;
  align-items: center;
  gap: 5px;
}

.categoryRemove {
  line-height: 0;
  /* color: var(--color-black-primary); */
}

.category svg {
  width: 11px;
  height: 11px;
}

.buttonAdd {
  min-height: 40px;
  margin: 0 auto;
  padding: 6px;
  border: 1px solid var(--grey-5);
  border-radius: var(--border-radius-s);
}

.counteragents {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  gap: 10px;
}

.counteragentsItem {
  display: flex;
  gap: 10px;
}

.buttonRemoveIcon {
  width: 16px;
  height: 16px;
}

.buttons>.button {
  flex: 1;
}

@media (max-width: 575px) {
  .dates {
    flex-wrap: wrap;
  }

  .datesCheckbox {
    flex-basis: 100%;
  }

  .datesCheckbox>label {
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}
