.root {
  position: relative;
  display: flex;
  flex-direction: column;
}

.dropdown {
  display: none;
  position: absolute;
  inset: var(--input-height) 0 auto;
  margin-top: 4px;
  background: var(--color-white-primary);
  box-shadow: 0px 6px 12px 0px #0000001A, 0px 0px 20px 0px #0000000D;
  border-radius: var(--border-radius-m);
  overflow: hidden;
  z-index: 100;
}

.dropdown.open {
  display: block;
}

.list {
  max-height: 290px;
  overflow-y: auto;
}

.option {
  padding: var(--select-dropdown-item-padding);
  user-select: none;
  cursor: default;
}

.option:hover,
.option:focus-visible,
.option.active {
  background: var(--grey-6);
}

.selected {
  margin-top: 8px;
}

.tags {
  margin-top: 8px;
}

.tag {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tagRemove {
  line-height: 0;
}

.tagRemove svg {
  width: 11px;
  height: 11px;
}
