.root {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.item {
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 400;
}

.item>label {
  padding: 0;
  justify-content: flex-start;
  gap: 8px;
}
