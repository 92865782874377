.root :global(.skeleton-loader) {
  border-radius: var(--border-radius-s);
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 8px;
}

.fileIcon {
  flex-shrink: 0;
  width: 42px;
  height: 42px;
}

.title {
  flex-grow: 1;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.1;
  word-wrap: break-word;
  overflow: hidden;
}

.controls {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--grey-3);
  gap: 15px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  line-height: 1.2;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-3);
  gap: 20px;
}

.featureLabel {
  margin-bottom: 5px;
  font-weight: 500;
}

.featureValue {
  color: var(--grey-1);
  min-height: 16.8px;
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 42%;
}

.checkbox {
  display: inline-block;
}

.outOfDate {
  margin-bottom: 10px;
  color: var(--grey-2);
  font-weight: 600;
}

.buttonSkelet {
  width: 24px;
  height: 24px;
}

.description {
  white-space: pre-wrap;
}

.buttons {
  margin-top: 20px;
}

.button {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
}
