.table {
  table-layout: fixed;
}

.cellIcon {
  width: 40px;
  min-width: 40px;
}

.cellCategory {
  width: 208px;
}

.cellContragent {
  width: 240px;
}

.cellValid {
  width: 130px;
}

.cellButtons {
  width: 55px;
  min-width: 55px;
}

.cellArrow {
  width: 23px;
  min-width: 23px;
  cursor: pointer;
}

.cellName {
  word-wrap: break-word;
  overflow: hidden;
  cursor: pointer;
}

.cellIcon,
.cellContragent {
  cursor: pointer;
}

.cellValid {
  text-align: right;
  color: var(--grey-1);
  cursor: pointer;
}

.cellControls {
  text-align: right;
}

.cellValid.gray {
  color: var(--grey-3);
}

.cellValid.orange {
  color: var(--color-orange-primary);
}

.cellValid.red {
  color: var(--color-red-primary);
}

.subTable.subTable {
  position: relative;
  width: calc(100% - 9px);
  padding-right: 0;
  padding-left: 12px;
  margin: -2px 0 -2px 10px;
  overflow: hidden;
}

.subTable::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-left: 1px solid var(--grey-3);
  height: calc(100% - 5px);
}

.minHeight {
  min-height: 71px;
}

.row.selected>td {
  background: var(--color-green-4);
}

.row.selected .dropdownBtn {
  background: var(--grey-5);
}

.selected.withChilds>td {
  background: var(--color-green-4);
  border-bottom: 0;
}

.selected.withChilds+.row>td {
  padding-top: 0;
  padding-bottom: 5px;
  line-height: 0;
  border-top: 0;
}

.inActive {
  opacity: 0.5;
}

.buttons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 7px;
  padding-right: 7px;
}

.buttons::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  height: 44px;
  border-left: 1px solid var(--grey-3);
  transform: translateY(-50%);
}

.icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fileIcon {
  flex-shrink: 0;
  margin-left: auto;
  width: 30px;
  height: 30px;
}

.moreLink {
  display: inline-block;
  padding: 1px 0;
  font-size: 0.625rem;
  color: var(--grey-2);
  cursor: pointer;
}

.buttonsDivider {
  height: 100%;
  min-height: 44px;
  width: 1px;
  background: var(--grey-3);
}

.button {
  color: var(--grey-3);
}

.dropdownBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 14px 3px 8px;
  height: 71px;
  width: 23px;
  background: var(--grey-6);
  border: none;
  box-shadow: none;
  border-radius: 0;
  text-align: center;
  outline: none;
  transition: color var(--transition), background var(--transition);
}

.selected .dropdownBtn {
  height: 91px;
}

.dropdownIcon {
  width: 12px;
  height: 12px;
  transition: transform var(--transition);
}

.dropdownIcon svg {
  width: 100%;
  height: 100%;
}

.handler {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 71px;
  width: 16px;
  text-align: center;
  background: var(--grey-6);
  color: var(--grey-3);
  transition: color var(--transition), background var(--transition);
}

.row:hover .dropdownBtn,
.row:hover .handler {
  background: var(--grey-5);
}

.row .dropdownBtn.open {
  background: var(--grey-4);
}

.row .open .dropdownIcon {
  transform: scaleY(-1);
}

.row:hover .dropdownBtn.open {
  background: var(--grey-4);
}

.name {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.tooltip:global(-content) {
  width: 250px;
  padding: 8px 10px;
}

.selectAll {
  display: inline-block;
}

.selectAll>label {
  flex-direction: row-reverse;
  gap: 8px;
}

.selectAll :global(.forma-ui__switcher__label) {
  font-size: 12px;
  font-weight: 500;
}

.checkbox>label {
  padding: 8px;
}

.checkboxIcon.checkboxIcon {
  width: 18px;
  height: 18px;
}

.row td {
  padding-top: 0;
  padding-bottom: 0;
}

.row .cellArrow {
  padding-left: 0;
}

.row .cellButtons {
  padding-right: 0;
}

.mainContragent {
  font-weight: 500;
  color: var(--color-black-primary);
}

.contragent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 801px) {
  .row .cellValid {
    font-weight: 300;
    line-height: 1.2;
  }

  .row .cellName {
    font-weight: 500;
    line-height: 1.2;
  }

  .row .cellContragent {
    font-weight: 300;
    line-height: 1.2;
    color: var(--grey-1);
  }

  .cellContragent span {
    max-height: calc(0.875rem * 1.2 * 2);
  }

  .name span {
    max-height: calc(0.875rem * 1.2 * 2);
  }
}

@media (min-width: 576px) {
  .cellContragent span {
    display: inline-block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: calc(0.875rem * 1.5 * 2);
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--grey-1);
    vertical-align: middle;
  }

  .name span {
    display: inline-block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: calc(0.875rem * 1.5 * 2);
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}

@media (max-width: 1280px) {
  .cellCategory {
    width: 180px;
  }

  .cellContragent {
    width: 180px;
  }
}

@media (max-width: 1024px) {
  .cellCategory {
    width: 120px;
  }

  .cellContragent {
    width: 140px;
  }

  .cellValid {
    width: 115px;
  }
}

@media (max-width: 575px) {
  .row.row {
    display: grid;
    grid-template-columns: 23px 30px auto;
    background: var(--grey-6);
    gap: 10px;
  }

  .row :global(.forma-ui__table__columnTitle) {
    display: none;
  }

  .row .cellArrow {
    padding: 0;
  }

  .row .cellIcon {
    height: 100%;
    padding: 10px 0;
  }

  .row .cellName {
    height: 100%;
    padding: 10px 10px 10px 0;
  }

  .row .cellCategory,
  .row .cellContragent,
  .row .cellValid {
    grid-column-start: 2;
    grid-column-end: 4;
    overflow: hidden;
    padding: 0 10px 0 0;
  }

  .row .cellButtons {
    display: none;
  }

  .name>span {
    max-width: 100%;
  }

  .buttons {
    justify-content: space-around;
  }

  .buttonsDivider {
    display: none;
  }

  .subTable {
    margin-left: 20px;
  }

  .cellValid {
    text-align: left;
  }

  .name {
    min-height: 0;
  }

  .fileIcon {
    margin-left: 0;
  }
}
