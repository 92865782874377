.agreement {
  margin-top: 8px;
  font-size: 12px;
  line-height: 1.16;
  color: var(--grey-2);
}

.agreement a {
  color: var(--primary-color);
  text-decoration: underline;
}

.checkCodeText {
  margin-top: 24px;
  font-size: 0.875rem;
  color: var(--grey-2);
}

.backButton {
  font-family: var(--main-font);
  color: var(--color-black-primary);
  gap: 0;
}

.inputs {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 16px;
}

.timer {
  margin-top: 50px;
  margin-bottom: 50px;
}

.anotherAction {
  margin-top: 24px;
  color: var(--grey-1);
}

.anotherAction a {
  color: var(--primary-color);
  text-decoration: underline;
}

.buttons {
  margin-top: 16px;
}

.buttonsContainer {
  justify-content: center;
}

.resendText {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--grey-2);
}

.resendButton {
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: underline;
}

.mailButton {
  box-shadow: inset 0 0 0 1px var(--grey-3);
  gap: 8px;
}

.mailButtonIcon {
  height: 24px;
  width: auto;
}

.notice {
  margin-top: 10px;
  padding: 15px 10px;
  background: var(--color-white-primary);
  color: #FFA88C;
  font-size: 0.875rem;
  border-radius: var(--border-radius-m);
}

.trial {
  padding: 8px;
  font-size: 12px;
  line-height: 1.16;
  background: var(--color-green-4);
  border-radius: var(--border-radius-s);
  color: var(--color-green-dark);
}

.regEmail {
  padding: 16px;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  background: var(--grey-7);
  border-radius: var(--border-radius-m);
  color: var(--grey-0);
}

.regEmail b {
  display: block;
  margin-top: 8px;
}

@media (max-width: 575px) {
  .inputs {
    gap: 25px;
  }

  .timer {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}
