.headCell {
  width: 15%;
  padding: 10px 8px;
  text-align: center;
  color: var(--grey-2);
}

.headCell:first-child {
  width: auto;
  text-align: left;
  padding-left: 8px;
}

.headCell:last-child {
  padding-right: 8px;
}

.cell {
  padding: 9px 4px;
  vertical-align: middle;
  border-top: 0;
  border-bottom: 0;
}

.cell:first-child {
  padding-left: 8px;
}

.cellSwitch {
  padding: 9px 4px;
  border-top: 0;
  border-bottom: 0;
}

.cellSwitch:last-child {
  padding-right: 4px;
}

.switcher>label {
  justify-content: center;
}

.row {
  background: var(--grey-7);
}

.row:nth-child(2n) {
  background: var(--color-white-primary);
}

@media (max-width: 575px) {
  .cellSwitch {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
  }

  .cellSwitch>div {
    margin-bottom: 0;
  }

  .switcher {
    flex: 0;
    margin-left: auto;
  }
}
