.root {
  margin-bottom: 26px;
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  margin-bottom: 12px;
  gap: 12px;
}

.search {
  flex-grow: 1;
}

.root button,
.root a {
  padding-top: 16px;
  padding-bottom: 16px;
}

.select {
  width: 200px;
}

.selectProlong {
  width: 225px
}

.selectContragent {
  flex-grow: 1;
  min-width: 40%;
  width: auto;
}

.label {
  display: inline-block;
  padding: 3px 10px;
  border-radius: var(--border-radius-l);
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 400;
}

.select :global(.rc-select-selector) {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .row {
    flex-wrap: wrap;
  }

  .root button,
  .root a,
  .select {
    flex: 1 1 40%;
  }

  .search {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 0;
  }

  .row {
    flex-direction: column;
  }

  .root button,
  .root a,
  .select {
    flex: 1 1 100%;
    width: 100%;
  }
}
