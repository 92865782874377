.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  padding: 32px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
  gap: 16px;
}

.title {
  margin-bottom: 8px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
}

.description {
  max-width: 564px;
  font-size: 14px;
  line-height: 1.28;
}

.items {
  display: flex;
  gap: 16px;
}

.item {
  background: #F0FAF0;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-s);
  padding: 8px 16px;
}

.name {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.28;
  color: var(--grey-2);
}

.price {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--primary-color);
}

@media (max-width: 1024px) {
  .items {
    flex-direction: column;
    gap: 6px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .name {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
    gap: 24px;
  }
}
