.root {
  position: relative;
}

.juristicName {
  width: 70%;
}

.inn {
  width: 30%;
}

.fio {
  width: 40%;
}

.date {
  width: 40%;
}

.selectContainer {
  margin-top: -12px;
  margin-bottom: -12px;
  margin-left: 12px;
}

.select :global(.rc-select-selector) {
  height: 42px;
  background: var(--grey-6);
  font-size: 0.875rem;
  font-weight: 600;
}

.select :global(.rc-select-selection-placeholder) {
  color: var(--color-black-primary);
}

.buttons {
  min-width: 46px;
  line-height: 0;
}

.remove {
  color: var(--grey-3);
}

.remove:hover {
  color: var(--error-color);
}

.subtitle {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
}

@media (min-width: 576px) {
  .cellWithLink {
    padding-top: 0;
    padding-bottom: 0;
  }

  .link {
    display: block;
    min-height: 44px;
    padding-top: 14px;
    padding-bottom: 14px;
    line-height: 1;
    cursor: pointer;
  }
}
