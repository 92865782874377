.root {
  display: flex;
  align-items: flex-start;
}

.content {
  flex: 0 0 21cm;
  max-width: 21cm;
}

.side {
  position: sticky;
  top: calc(var(--header-height) + 15px);
  padding: 4px 0 0 25px;
  max-height: calc(100vh - var(--header-height) - 15px - 40px);
  display: flex;
  flex-direction: column;
  flex: 1 0;
  z-index: 100;
}

.modalDescription {
  margin: 2px 0;
  font-size: 1.11rem;
  text-align: center;
}

.saveButton {
  max-width: 330px;
  margin: 0 auto 43px;
}

.panel {
  position: relative;
}

.content :global(.ck-sticky-panel__placeholder) {
  display: none !important;
}

.content :global(.ck-editor__main) {
  margin-top: 0;
}

.content :global(.ck-editor__top) {
  position: sticky !important;
  top: calc(var(--header-height) + 15px);
  padding-bottom: 15px !important;
  z-index: var(--ck-z-panel);
}

.content :global(.ck-editor__top::before) {
  content: '';
  position: absolute;
  top: -15px;
  left: -2%;
  width: 145%;
  height: calc(100% + 15px);
  background: var(--body-bg);
  z-index: -1;
}

.content :global(.ck.ck-toolbar) {
  border: none;
}

.content :global(.ck .variable .variable__text.warning) {
  background: #FD9843;
}

@media (max-width: 1280px) {
  /* .root {
    overflow-x: scroll;
  } */

  .side {
    flex: 0 0 300px;
  }
}
