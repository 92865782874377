.items {
  padding: 20px 34px;
  background: var(--color-white-primary);
  border-radius: var(--border-radius-m);
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--grey-3);
}

.header {
  display: flex;
  align-items: center;
  min-height: 56px;
  gap: 8px;
}

.title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
}

.name {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
}

.name:global(.skeleton-loader) {
  min-height: 1.3rem;
  min-width: 180px;
  border-radius: var(--border-radius-xs);
}

.dropdownIcon {
  margin-left: auto;
  padding: 20px 12px;
  line-height: 0;
  transition: transform var(--transition);
}

.dropdownIcon.open {
  transform: scaleY(-1);
}

.dropdownIcon svg {
  width: 16px;
  height: 16px;
}

.button {
  min-height: 40px;
  padding: 6px 16px;
  font-size: 15px;
  font-weight: 500;
  border-radius: var(--border-radius-s);
}

.buttonMore {
  min-height: 40px;
  min-width: 40px;
  padding: 4px;
  border-radius: var(--border-radius-s);
}

.input {
  --input-font-size: 14px;
  --input-height: 40px;
  --input-radius: var(--border-radius-s);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

:global([role="tooltip"].groups-tooltip-content) {
  padding: 20px;
  max-width: 410px;
  font-size: 0.875rem;
}

.buttons {
  justify-content: center;
}

.buttonCreate {
  padding: 16px;
}

@media (max-width: 575px) {
  .items {
    padding: 0 12px;
  }

  .buttons {
    align-items: center;
  }

  .header {
    gap: 0;
  }
}
